interface IDVE {
  BIR: number;
  Chassi: string;
  "Concessionária": string;
  "Data NF": number;
  Modelo: string;
  "Nome do Cliente": string;
  "Proveniente de Refaturamento": string;
  "Qtde/total geral": number;
  "Tipo Cliente": string;
}

interface IDVRTitle {
  __EMPTY: "REGIONAL";
  __EMPTY_1: "BIR";
  __EMPTY_2: "CONCESSIONÁRIA";
  __EMPTY_3: "FATURAMENTO DEZ";
  __EMPTY_5: "MODELO";
  __EMPTY_6: "FATURAMENTO DEZ";
  __rowNum__: number;
}

interface IDVRRow {
  __EMPTY: string;
  __EMPTY_1: number;
  __EMPTY_2: string;
  __EMPTY_3: number;
  __EMPTY_5?: string;
  __EMPTY_6?: number;
  __rowNum__: number;
}
interface IDVRTotal {
  __EMPTY: "TOTAL REDE";
  __EMPTY_3: number;
  __rowNum__: number;
}

interface IBillingFile {
  DVE: IDVE[];
  DVR: Array<IDVRTitle | IDVRRow | IDVRTotal>;
}

enum EValidationCodeErro {
  NOT_OBJECT = "NOT_OBJECT",
  IS_NOT_NULL = "IS_NOT_NULL",
  DVE_IS_NOT_ARRAY = "DVE_IS_NOT_ARRAY",
  DVR_IS_NOT_ARRAY = "DVR_IS_NOT_ARRAY",
  DVR_TITLE_INVALID = "DVR_TITLE_INVALID",
  DVR_LINHA_TOTAL = "DVR_LINHA_TOTAL",
  DVR_LINHA = "DVR_LINHA",
  DVE_LINHA = "DVE_LINHA",
}

enum EDVRColumnTitles {
  REGIONAL = "REGIONAL",
  BIR = "BIR",
  CONCESSIONARIA = "CONCESSIONÁRIA",
  FATURAMENTO_DEZ_CONCESSIONARIA = "FATURAMENTO DEZ",
  MODELO = "MODELO",
  FATURAMENTO_DEZ_MODELO = "FATURAMENTO DEZ",
}

enum EDVEColumnTitles {
  MODELO = "Modelo",
  DATA_NF = "Data NF",
  CHASSI = "Chassi",
  NOME_CLIENTE = "Nome do Cliente",
  BIR = "BIR",
  CONCESSIONARIA = "Concessionária",
  TIPO_CLIENTE = "Tipo Cliente",
  QTD_GERAL = "Qtde/total geral",
}

type TValidationCodeErroStrings = keyof typeof EValidationCodeErro;

class ValidatorToBillingFile {
  validationErroLog: string[] = [];

  isBillingFile(obj: any): obj is IBillingFile {
    const isObject = typeof obj === "object";
    const isNotNull = obj !== null;
    const isArrayDVE = this.isArray(obj.DVE);
    const isArrayDVR = this.isArray(obj.DVR);
    let isDVEValid = true;
    let isDVRValid = true;
    let isBiillingFile = true;

    this.validationErroLog = [];

    if (isObject && isNotNull && isArrayDVE && isArrayDVR) {
      obj.DVE.forEach((item: any) => {
        const isValid = this.isIDVE(item);

        if (!isValid) isDVEValid = isValid;
      });
      obj.DVR.forEach((item: IDVRTitle | IDVRRow | IDVRTotal) => {
        let isValid = true;
        if (item.__rowNum__ >= 4) {
          let isDVRTitle = false;
          let isDVRTotal = false;
          let isDVRRow = false;

          if (item.__rowNum__ == 4) {
            isDVRTitle = this.isIDVRTitle(item);

            if (!isDVRTitle) {
              this.registerValidationErroLog(
                EValidationCodeErro.DVR_TITLE_INVALID,
                `Linha 5: ${JSON.stringify(item)}`
              );
            }
          } else if ("__EMPTY" in item && item.__EMPTY == "TOTAL REDE") {
            isDVRTotal = this.isIDVRTotal(item);

            if (!isDVRTotal) {
              this.registerValidationErroLog(
                EValidationCodeErro.DVR_LINHA_TOTAL,
                `Linha ${item.__rowNum__}: ${JSON.stringify(item)}`
              );
            }
          } else {
            isDVRRow = this.isIDVRRow(item);
          }

          isValid = isDVRTitle || isDVRRow || isDVRTotal;
        }

        if (!isValid) isDVRValid = isValid;
      });

      isBiillingFile = isDVEValid && isDVRValid;
    } else {
      isBiillingFile = false;
    }

    const validObject = {
      NOT_OBJECT: isObject,
      IS_NOT_NULL: isNotNull,
      DVE_IS_NOT_ARRAY: isArrayDVE,
      DVR_IS_NOT_ARRAY: isArrayDVR,
    };

    Object.entries(validObject).forEach(([attr, value]) => {
      if (!value) this.registerValidationErroLog(attr);
    });

    return isBiillingFile;
  }

  isArray(obj: any): obj is any[] {
    return Array.isArray(obj);
  }

  isDate(value: any): boolean {
    if (isNaN(value)) return false;

    const data = new Date((value - 1) * 24 * 60 * 60 * 1000);

    if (isNaN(data.getTime())) return false;

    return true;
  }

  isIDVE(obj: any): obj is IDVE {
    const modeloValid = typeof obj[EDVEColumnTitles.MODELO] === "string";
    const dataNFValid = this.isDate(obj[EDVEColumnTitles.DATA_NF]);
    const chassiValid = typeof obj[EDVEColumnTitles.CHASSI] === "string";
    const clientNameValid =
      typeof obj[EDVEColumnTitles.NOME_CLIENTE] === "string";
    const birValid = typeof obj[EDVEColumnTitles.BIR] === "number";
    const businessValid =
      typeof obj[EDVEColumnTitles.CONCESSIONARIA] === "string";
    const clienteTypeValid =
      typeof obj[EDVEColumnTitles.TIPO_CLIENTE] === "string";
    const qtdGeralValid = typeof obj[EDVEColumnTitles.QTD_GERAL] === "number";
    const rowNum = obj.__rowNum__ + 1;

    if (!modeloValid) {
      this.registerValidationErroLog(
        EValidationCodeErro.DVE_LINHA,
        this.resolveMsgErro(EDVEColumnTitles.MODELO, "texto", rowNum)
      );
    }

    if (!dataNFValid) {
      this.registerValidationErroLog(
        EValidationCodeErro.DVE_LINHA,
        this.resolveMsgErro(EDVEColumnTitles.DATA_NF, "texto", rowNum)
      );
    }

    if (!chassiValid) {
      this.registerValidationErroLog(
        EValidationCodeErro.DVE_LINHA,
        this.resolveMsgErro(EDVEColumnTitles.CHASSI, "texto", rowNum)
      );
    }

    if (!clientNameValid) {
      this.registerValidationErroLog(
        EValidationCodeErro.DVE_LINHA,
        this.resolveMsgErro(EDVEColumnTitles.NOME_CLIENTE, "texto", rowNum)
      );
    }

    if (!birValid) {
      this.registerValidationErroLog(
        EValidationCodeErro.DVE_LINHA,
        this.resolveMsgErro(EDVEColumnTitles.BIR, "numérico", rowNum)
      );
    }

    if (!businessValid) {
      this.registerValidationErroLog(
        EValidationCodeErro.DVE_LINHA,
        this.resolveMsgErro(EDVEColumnTitles.CONCESSIONARIA, "texto", rowNum)
      );
    }

    if (!clienteTypeValid) {
      this.registerValidationErroLog(
        EValidationCodeErro.DVE_LINHA,
        this.resolveMsgErro(EDVEColumnTitles.TIPO_CLIENTE, "texto", rowNum)
      );
    }

    if (!qtdGeralValid) {
      this.registerValidationErroLog(
        EValidationCodeErro.DVE_LINHA,
        this.resolveMsgErro(EDVEColumnTitles.QTD_GERAL, "numérico", rowNum)
      );
    }

    return (
      typeof obj === "object" &&
      obj !== null &&
      modeloValid &&
      dataNFValid &&
      chassiValid &&
      clientNameValid &&
      birValid &&
      businessValid &&
      clienteTypeValid &&
      qtdGeralValid
    );
  }

  isIDVRTitle(obj: any): obj is IDVRTitle {
    if (obj.__rowNum__ >= 4) {
      const isValid =
        typeof obj === "object" &&
        obj !== null &&
        obj.__EMPTY === EDVRColumnTitles.REGIONAL &&
        obj.__EMPTY_1 === EDVRColumnTitles.BIR &&
        obj.__EMPTY_2 === EDVRColumnTitles.CONCESSIONARIA &&
        obj.__EMPTY_3 === EDVRColumnTitles.FATURAMENTO_DEZ_CONCESSIONARIA &&
        obj.__EMPTY_5 === EDVRColumnTitles.MODELO &&
        obj.__EMPTY_6 === EDVRColumnTitles.FATURAMENTO_DEZ_MODELO;
      return isValid;
    } else {
      return true;
    }
  }

  isIDVRRow(obj: any): obj is IDVRRow {
    const emptyIsValid = typeof obj.__EMPTY === "string";
    const empty1IsValid = typeof obj.__EMPTY_1 === "number";
    const empty2IsValid = typeof obj.__EMPTY_2 === "string";
    const empty3IsValid = typeof obj.__EMPTY_3 === "number";
    let empty5IsValid = true;
    let empty6IsValid = true;
    const rowNum = obj.__rowNum__ + 1;

    if (obj.__EMPTY_5) {
      empty5IsValid = typeof obj.__EMPTY_5 === "string";
    }

    if (obj.__EMPTY_6) {
      empty6IsValid = typeof obj.__EMPTY_6 === "number";
    }

    if (!emptyIsValid) {
      this.registerValidationErroLog(
        EValidationCodeErro.DVR_LINHA,
        this.resolveMsgErro(EDVRColumnTitles.REGIONAL, "texto", rowNum)
      );
    }

    if (!empty1IsValid) {
      this.registerValidationErroLog(
        EValidationCodeErro.DVR_LINHA,
        this.resolveMsgErro(EDVRColumnTitles.BIR, "numérico", rowNum)
      );
    }

    if (!empty2IsValid) {
      this.registerValidationErroLog(
        EValidationCodeErro.DVR_LINHA,
        this.resolveMsgErro(
          EDVRColumnTitles.CONCESSIONARIA,
          "texto",
          rowNum
        )
      );
    }

    if (!empty3IsValid) {
      this.registerValidationErroLog(
        EValidationCodeErro.DVR_LINHA,
        this.resolveMsgErro(
          EDVRColumnTitles.FATURAMENTO_DEZ_CONCESSIONARIA,
          "numérico",
          rowNum
        )
      );
    }

    if (!empty5IsValid) {
      this.registerValidationErroLog(
        EValidationCodeErro.DVR_LINHA,
        this.resolveMsgErro(EDVRColumnTitles.MODELO, "texto", rowNum)
      );
    }

    if (!empty6IsValid) {
      this.registerValidationErroLog(
        EValidationCodeErro.DVR_LINHA,
        this.resolveMsgErro(
          EDVRColumnTitles.FATURAMENTO_DEZ_MODELO,
          "numérico",
          rowNum
        )
      );
    }

    return (
      typeof obj === "object" &&
      obj !== null &&
      emptyIsValid &&
      empty1IsValid &&
      empty2IsValid &&
      empty3IsValid &&
      empty5IsValid &&
      empty6IsValid
    );
  }

  isIDVRTotal(obj: any): obj is IDVRTotal {
    const emptyIsValid = typeof obj.__EMPTY === "string";
    const empty3IsValid = typeof obj.__EMPTY_3 === "number";

    if (!emptyIsValid) {
      this.registerValidationErroLog(
        EValidationCodeErro.DVR_TITLE_INVALID,
        this.resolveMsgErro("TOTAL REDE", "texto", obj.__rowNum__)
      );
    }

    if (!empty3IsValid) {
      this.registerValidationErroLog(
        EValidationCodeErro.DVR_LINHA,
        this.resolveMsgErro("VALOR TOTAL REDE", "numérico", obj.__rowNum__)
      );
    }

    return (
      typeof obj === "object" && obj !== null && emptyIsValid && empty3IsValid
    );
  }

  resolveMsgErro(
    column: EDVRColumnTitles | string,
    type: "texto" | "numérico",
    rowIndex: number
  ): string {
    return `O campo ${column} não possui um valor válido, o mesmo deve ser ${type}. Linha ${rowIndex}`;
  }

  registerValidationErroLog(
    codeErro: TValidationCodeErroStrings | string,
    msgConcat: string = ""
  ) {
    let msg = "";

    switch (codeErro) {
      case EValidationCodeErro.NOT_OBJECT:
        msg = "[ESTRUTURA] O arquivo não possuí planilhas DVE e/ou DVR";
        break;
      case EValidationCodeErro.IS_NOT_NULL:
        msg = "[DADOS] A planilha está vazia";
        break;
      case EValidationCodeErro.DVE_IS_NOT_ARRAY:
        msg =
          "[ESTRUTURA] DVE: não está de acordo com os padrões de importação";
        break;
      case EValidationCodeErro.DVR_IS_NOT_ARRAY:
        msg =
          "[ESTRUTURA] DVR: não está de acordo com os padrões de importação";
        break;
      case EValidationCodeErro.DVR_TITLE_INVALID:
        msg = "[DVR - Título] O título da planilha DVR está fora dos padrões";
        break;
      case EValidationCodeErro.DVR_LINHA_TOTAL:
        msg = "[DVR - Total] A linha total não corresponde ao padrão";
        break;
      case EValidationCodeErro.DVR_LINHA:
        msg = "[DVR - Linha] Os valores da linha não está correto";
        break;
      case EValidationCodeErro.DVE_LINHA:
        msg = "[DVE - Linha] Os valores da linha não está correto";
        break;

      default:
        msg = "[ERRO] Foi encontrado uma inconsistência não mapeada no arquivo";
        break;
    }

    msg += msgConcat != "" ? ` - ${msgConcat}` : "";
    this.validationErroLog.push(msg);
  }
}

const useValidatorToBillingFile = new ValidatorToBillingFile();
export default useValidatorToBillingFile;
